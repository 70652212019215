@import './src/scss/variables';

.details-panel {
  display: flex;
 // height: 100%;
  overflow: auto;
  flex-direction: column;

  &-btn {
    color: #fff;
    background-color: $blue-01;
    border-radius: 4px;
    font-weight: bold;
    font-size: 10px;
    height: $form-item-height;
    align-self: flex-end;
    margin: 15px 20px 15px;
    box-shadow: $btn-primary-shadow;
  }

  .details-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    > span {
      font-size: 12px;
      font-weight: 700;
      color: $blue-01;
      padding-left: 15px;
    }
  }
}
