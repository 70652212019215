//@import '~antd/dist/antd';
//$primary-color: #003366;
@import 'scss/theme';
@import 'scss/variables';
@import 'scss/tabs';
@import 'scss/buttons';
@import 'scss/form';
@import 'scss/grid';
@import 'scss/notifications';

$font: 'ProximaNova';

@font-face {
  font-family: $font;
  src: local($font), url(assets/fonts/ProximaNova.otf) format('opentype');
}

html,
body {
  margin: 0;
  font-family: $font !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;

  #root,
  #root > div {
    height: 100%;
  }
}

.link-cell-text {
  @include label-text;
  font-size: 11px;
  text-decoration: underline;
  cursor: pointer;
}

.secondary-link-cell-text {
  @include label-text;
  font-size: 11px;
  color: $blue-04;
  text-decoration: underline;
  cursor: pointer;
}

textarea:focus,
textarea.form-control,
input.form-control,
input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
[type='text'].form-control,
[type='password'].form-control,
[type='email'].form-control,
[type='tel'].form-control,
[contenteditable].form-control {
  &:focus {
    outline: 0;
    box-shadow: inset 0 0 0 #ddd;
  }
}

.med-custom-collapse {
  height: 100%;
  overflow: auto;
  
  .med-custom-panel {
    width: 100% !important;
    margin-bottom: 15px;
    padding-left: 15px;
    padding-right: 15px;
    //width: 320px;

    .ant-collapse-header {
      height: 30px;
      display: flex;
      align-items: center;
      @include header;
    }

    .ant-collapse-content-box {
      background-color: #fff;
      filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.login-screen {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 240px;
    height: 320px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    padding: 32px;

    .ant-typography {
      text-align: center;
    }
  }
}
