@import './src/scss/variables';

.table-container {
  max-width: 100%;
  padding-right: 10px;

  .table-wrapper {
    position: relative;

    .add-btn {
      position: absolute;
      bottom: -10px;
      right: -10px;
      height: 40px;
      width: 40px;
      background-color: $highlight-blue;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: drop-shadow(2px 6px 7px rgba(0, 0, 0, 0.1));
      cursor: pointer;

      &:focus, &:hover, &:active {
        background-color: $blue-01;
      }

      &.disabled {
        background-color: $gray-02;
        cursor: not-allowed;

        &:focus, &:hover {
          background-color: $gray-02;
          border-color: $gray-02;
        }
      }
    }
  }

  .table-selected-cell {
    color: #DC6C87;
  }

  .pagination-container {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pagination-actions-wrapper {
      display: flex;
      align-items: center;

      .select-all-btn {
        margin-left: 30px;
        color: $blue-01;
        border-color: $blue-01;
        padding: 0 15px;
        font-size: 12px;
        height: 24px;
        line-height: 24px;
      }
    }

    .total-container {
      margin-top: -8px;

      @include total;
    }

    .logo {
      @include bottom-logo;
    }
  }
}

