.App {
  height: 100%;
  width: 100%;
  display: flex;

  .container {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, rgba(112, 198, 234, 0.3) 0%, rgba(112, 198, 234, 0) 19.76%);
  }

  main {
    width: 100%;
    height: calc(100% - 100px);
    padding: 0 10px 22px 20px;
  }

  .unauthenticated-text {
    color: #003366;
    margin: 20px 20px;
    font-weight: 700;
    font-size: 16px;
    text-align: right;
  }
}


