@import './src/scss/variables';

.map-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;

  .map-content {
    width: 100%;
    height: 100%;
  }

  .map-footer {
    margin-top: 5px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }
}


.total-container {
  @include total;

  .total-title {
    margin-bottom: 2px;
  }
}

.logo {
  @include bottom-logo;
}
