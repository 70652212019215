// colors
$blue-01: #003366;
$blue-01-at-30: #0033664C;
$blue-01-at-40: rgba(0, 51, 102, 0.4);
$blue-02: #40668C;
$blue-02-at-30: #40668C4D;
$blue-03: #EEF5FC;
$blue-03-at-30: #EEF5FC4C;
$blue-04: #5377b0;
$highlight-blue: #70C6EA;
$highlight-blue-at-30: #70C6EA4D;
$highlight-blue-at-30-no-transparent: #cee9f5;
$highlight-blue-at-30-no-transparent-light: #e5f2f8;
$black: #231F20;
$gray: #545454;
$gray-00: #757575;
$gray-02: #DFDFDF;
$red: #D14D4D;
$red-light: #D14D4D4C;
$green: #45A58E;
$green-light: #45A58E66;
$orange: #FF991F;
$orange-light: #FF991F66;
$highlight-light: #d5e4eb4d;

$tab-disabled: #0000003F;
$form-text-disabled: #000000BF;

$btn-primary-shadow: 0 2px 2px $blue-02-at-30;
$btn-default-shadow: 0 2px 2px $highlight-blue-at-30;

// sizes
$header-height: 40px;
$form-item-height: 24px;
$default-border-radius: 2px;

// mixins
@mixin label-text {
  font-weight: 700;
  font-size: 9px;
  color: $blue-01;
}

@mixin link-cell-text {
  font-size: 11px;
  text-decoration: underline;
  font-weight: 700;
  color: $blue-01;
  &-secondary {
    color: $blue-04;
  }
}

@mixin header {
  border-radius: 4px;
  background: $highlight-blue-at-30;
  border: 2px solid $highlight-blue-at-30;
  font-weight: 800;
  font-size: 10px;
}

@mixin btn {
  border-color: $highlight-blue;
  font-weight: 700;
}

@mixin form-element {
  border-radius: $default-border-radius;
  font-size: 12px;
  height: $form-item-height;
  padding: 0 10px;
  &:placeholder-shown {
    font-size: 11px;
  }
}

@mixin tag-container {
  margin: 0 2px 0 0;
  font-size: 10px;
  line-height: 1.4;
  padding: 2px 4px;
  color: $black;
  font-weight: normal;

  .ant-tag-close-icon {
    color: $black;
  }

  &.status-closed {
    background-color: $red-light;
    border: 1px solid $red;
  }

  &.status-active {
    background-color: $green-light;
    border: 1px solid $green;
  }

  &.status-hold, &.status-inactive {
    background-color: $orange-light;
    border: 1px solid $orange;
  }
}

@mixin total {
  width: 90px;
  background-color: $blue-02;
  margin-left: 30px;
  margin-right: 20px;
  color: #fff;
  padding: 0 8px;
  font-weight: 700;
  font-size: 10px;
  border-radius: 0 0 2px 2px;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));

  .total-title {
    line-height: 1;
    padding: 6px 0 0;

    .total-selected-rows {
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        cursor: pointer;
        height: 12px;
      }
    }
  }

  .total-count {
    font-weight: 800;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 14px;
    }
  }
}

@mixin bottom-logo {
  padding-left: 10px;
  margin-right: 50px;
}

@mixin input-clear-suffix {
  & .ant-input-suffix {
    display: none;
  }

  &:hover {
    & .ant-input-suffix {
      display: flex;
    }
  }
}
