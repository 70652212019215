@import 'variables';

button {
  &.ant-btn-primary {
    @include btn;
    background: $highlight-blue;
    box-shadow: $btn-primary-shadow;

    &:disabled,
    &:disabled:hover{
      border-color: $gray-02;
      background: $gray-02;
    }
  }

  &.ant-btn-default {
    @include btn;

    color: $highlight-blue;
    border-width: 2px;
  }

}

.btn-form-action {
  margin-top: 16px;
  width: 100%;
}
