@import './src/scss/variables';

.doctor-panel {
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 15px 15px;
    left: 0;
    top: 0;
    position: absolute;
  }

  &-top {
    display: flex;
    justify-content: space-between;
    &-left {
      position: relative;
    }
    &-right {
      height: 84px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    &-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &-switch {
      margin-right: 55px;
      background-color: #fff;
      border: 1px solid #d4dee4;
      width: 65px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;

      &:focus {
        box-shadow: none;
      }

      span {
        color: $blue-01;
        font-size: 9px;
        font-weight: 700;
      }
    }

    &-edit {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    &-name {
      @include link-cell-text;
      font-size: 13px;
    }
    &-id {
      font-size: 12px;
      z-index: 5;
    }
  }

  &-avatar {
    background-color: #c6d0dc;
  }

  &-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 6px;
  }

  &-label {
    width: 204px !important;

    &-short {
      width: 97px !important;
    }
  }

  &-field-editable {
    height: 440px !important;
  }

  &-field {
    font-size: 11px;

    &-editable {
      resize: none;
    }
  }

  &-line {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 60px;
    background-color: #f6f6f6;
    border-bottom: 2px solid #d4dee4;
  }

  &-button,
  &-button-save,
  &-button-edit {
    border: none;
  }

  &-button-save,
  &-button-cancel {
    width: 77px;
    height: 28px;
  }

  &-button,
  &-button-edit {
    padding: 0;
  }

  &-button-edit {
    &:disabled {
      background-color: transparent;

      &:hover, &:focus {
        background-color: transparent;
      }

      img, svg {
        filter: opacity(0.3) grayscale(100%);
      }
    }
  }

  &-button,
  &-button:hover {
    background-color: transparent;
  }

  &-button-save,
  &-button-save:hover &-button-save:focus {
    background-color: $blue-01 !important;
    color: #fff !important;
  }

  &-close-icon,
  &-edit-icon {
    color: $blue-01;
  }

  &-close-icon {
    font-size: 20px;
  }

  &-edit-icon {
    font-size: 16px;
  }

  &-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.camera-icon {
  cursor: pointer;
  svg {
    font-size: 45px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 4;
  }
}

.ant-switch-checked {
  div:before {
    background-color: red !important;
  }
}
